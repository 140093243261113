<template>
  <div 
    :class="{ 'pa-4': breakpoint('xs')}"
    class="support"
    v-resize="onResize"
  >
    <div 
      ref="container"
      class="chat-container rounded d-flex white pa-0 overflow-hidden"
    >
      <driver-list
        :list="list.items"
        flat
        :loading="controller.list.loading"
        :selectable="false"
        :toolbar="false"
        width="320px"
        :max-height="view.list.height"
        class="list snap-child transparent flex-shrink-0 ma-0"
        @get-profile="(ticket) => select(queue, ticket)"
      >
        <template v-slot:header>
          <v-subheader 
            ref="list-header"
            class="px-2"
          >
            <v-menu
              transition="slide-y-transition"
              offset-y
              min-width="240px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  small
                  v-on="on"
                  v-bind="attrs"
                >
                  {{ !!queue ? list.name : '...' }}
                  <v-icon 
                    v-show="!!queue"
                    small
                    right
                  >{{ icons.menu }}</v-icon>
                </v-btn>
              </template>
              <v-list
                max-height="40vh"
                class="scrollable"
              >
                <v-list-item
                  v-for="item in queues"
                  :key="'queue-'+item.id"
                  :class="{ 'primary--text': item.id==queue }"
                  class="text-overline"
                  @click="select(item.id)"
                >
                  {{ item.name }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-subheader>
        </template>
        <template v-slot:item-avatar="{ driver: ticket }">
          <v-list-item-avatar
            v-if="breakpoint('xs')"
          >
            <v-img 
              v-if="!!ticket.driver&&!!ticket.driver.photo"
              :src="ticket.driver.photo" 
            />
            <icon-base
              v-else
              width="96"
              height="96"
              icon-name="avatar"
              class="avatar-default"
            >
              <avatar-default />
            </icon-base>
          </v-list-item-avatar>
        </template>
        <template v-slot:item-content="{ driver: ticket }">
          <v-list-item-content>
            <v-list-item-title>
              {{ ticket.summary }}
            </v-list-item-title>
            <v-list-item-subtitle class="text-truncate-2">
              {{ ticket | brief(format) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </driver-list>
      <v-divider vertical />
      <div 
        class="chat flex-grow-1 d-flex flex-column"
      >
        <div
          v-if="!!ticket"
          ref="chat-header"
          class="chat-header px-4"
        >
          <div 
            class="d-flex align-center pa-0"
          >
            <v-avatar 
              size="40"
              class="my-3"
            >
              <v-img 
                v-if="!!ticket.driver&&!!ticket.driver.photo"
                :src="ticket.driver.photo" 
              />
              <icon-base
                v-else
                width="96"
                height="96"
                icon-name="avatar"
                class="avatar-default"
              >
                <avatar-default />
              </icon-base>
            </v-avatar>
            <div class="ml-4 my-2">
              <div class="title text-subtitle-1 mb-n1">
                {{ ticket.summary }}
              </div>
              <div class="subtitle d-inline-block text-body-2 text--secondary text-truncate">
                {{ ticket.name }}
              </div>
            </div>
            <v-spacer />
            <v-btn
              v-if="showInfo"
              icon
              :href="ticket.link"
              target="_blank"
              class="mx-2"
            >
              <v-icon size="20">{{ icons.link }}</v-icon>
            </v-btn>
            <v-btn
              v-show="breakpoint(null, 'sm')&&showInfo"
              icon
              @click="() => toggleDescription()"
            >
              <v-icon size="20">{{ icons.text }}</v-icon>
            </v-btn>
          </div>
          <!-- <v-progress-linear
            v-if="loading"
            indeterminate
            absolute
            bottom
            height="2"
            color="secondary"
            class="my-0"
          /> -->
        </div>
        <div
          ref="chat-box"
          class="chat-box overflow-scroll"
          :style="{ 'max-height': view.chat.height + 'px' }"
        >
          <bot-ui 
            :id="!!ticket ? ticket.id : null"
            :history="!!ticket ? ticket.messages : []"
            :data="{ ...user, storage, files: !!ticket ? ticket.files : [] }"
            :container="$refs['chat-box']"
            :loading="controller.chat.loading"
            :support="true"
            :dark="false"
            class="pb-16 px-4"
            @message-click="onMessageClick"
          />
        </div>
        <div
          class="field-container pa-4"
        >
          <v-textarea 
            v-model="controller.chat.input"
            :placeholder="controller.chat.label"
            :disabled="controller.chat.disabled"
            :loading="controller.chat.loading"
            :append-icon="isTraining ? icons.train : icons.send"
            solo
            rows="1"
            auto-grow
            hide-details
            class="field message"
            @keyup.ctrl.enter.prevent="message"
            @click:append="message"
          >
            <template #prepend>
              <v-btn
                v-show="!!ticket&&ticket.id!=='bot'"
                :loading="controller.chat.loading"
                fab
                small
                class="mr-2 mt-n2"
                @click="assistant()"
              >
                <v-avatar 
                  size="36"
                >
                  <v-img 
                    :src="require('@/assets/img/driver-bot-avatar-72.png')" 
                  />
                </v-avatar>
              </v-btn>
            </template>
          </v-textarea>
        </div>
      </div>

      <v-navigation-drawer
        v-if="showInfo"
        v-model="controller.chat.info"
        :absolute="breakpoint(null, 'sm')"
        :permanent="breakpoint('sm')"
        floating
        right
        :width="view.info.width"
        class="flex-shrink-0"
      >
        <div class="info-fields pa-4">
          <v-text-field
            v-model="controller.chat.summary.value"
            :loading="controller.chat.summary.loading"
            :disabled="controller.chat.summary.disabled"
            :label="controller.chat.summary.label"
            dense
            outlined
            hide-details
            class="mb-4"
            @change="update('summary')"
          />
          <v-select
            v-model="controller.chat.status.value"
            :items="controller.chat.status.options"
            :loading="controller.chat.status.loading"
            :disabled="controller.chat.status.disabled"
            :label="controller.chat.status.label"
            item-text="name"
            item-value="id"
            hide-selected
            dense
            outlined
            hide-details
            class="mb-4"
            @change="update('status')"
          >
            <template v-slot:selection="{ item, index }">
              <span>
                {{ !!item ? item.name : null }}
              </span>
            </template>
            <template v-slot:item="{ item, index }">
              <span class="text-body-2">
                {{ item.name }}
              </span>
              <v-spacer />
              <v-icon small>{{ icons.arrow }}</v-icon>
              <v-chip
                label
                x-small
                class="text-overline ml-2 px-1"
              >
                {{ item | transition(controller.chat.status.transitions) }}
              </v-chip>
            </template>
          </v-select>
          <v-select
            v-model="controller.chat.type.value"
            :label="controller.chat.type.label"
            :items="controller.chat.type.options"
            :loading="controller.chat.type.loading"
            :disabled="controller.chat.type.disabled"
            dense
            outlined
            hide-details
            class="mb-4"
            @change="update('type')"
          />
          <m-field
            light
            outlined
            :label="controller.chat.category.label"
            :loading="controller.chat.category.loading"
          >
            <div class="d-flex">
              <v-autocomplete
                v-model="controller.chat.category.value"
                :label="controller.chat.category.label"
                :items="categories"
                :disabled="controller.chat.category.disabled"
                dense
                solo
                flat
                hide-details
              />
              <v-divider vertical class="mx-1" />
              <v-autocomplete
                v-model="controller.chat.category.child"
                :items="subcategories"
                :disabled="controller.chat.category.disabled"
                dense
                solo
                flat
                hide-details
                @change="update('category')"
              />
            </div>
          </m-field>
        </div>
        <!-- <v-divider /> -->
        <div 
          v-if="ticket.hasOwnProperty('fields')"
          class="text-caption text--secondary px-4 pb-4"
        >
          Criado {{ ticket.fields.created | dateFormat }} | Atualizado {{ ticket.fields.updated | dateFormat }}
        </div>
        <p 
          class="text-body-2 px-4"
          :inner-html.prop="ticket.description | formatter(formatText)"
        />
      </v-navigation-drawer>
    </div>
  </div>
</template>

<style lang="scss">

  .support .chat-container {
    position: relative;
    height: calc(100vh - 96px);
  }
  .support .chat {
    position: relative;
  }
  .support .chat .field-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .support .chat .botui-message.human .text {
    color: var(--mobees-black) !important;
    background: var(--v-soft-base) !important;
  }
  .support .field.message textarea {
    line-height: 1.5rem;
  }
  //   position: fixed;
  //   left: 0;
  //   width: 100%;
  //   background: var(--mobees-black);
  //   z-index: 5;
  // }

  // .support .chat .chat-header .title {
  //   font-size: .875rem !important;
  //   line-height: 1.5rem;
  // }
</style>

<script>
  import services from '@/services'
  import { mdiClose, mdiMenuDown, mdiSend, mdiShimmer, mdiText, mdiArrowRightThin, mdiLink } from '@mdi/js';
  import moment from 'moment';
  import {
    sync,
  } from 'vuex-pathify'
  import BotUi, { botui } from '@/components/BotUi.vue'
  import { status, types, categories, parseComments, markdown } from '@/services/support'

  import device from 'mobile-device-detect';
  const md5 = require('md5');


  export default {
    name: 'Support',
    metaInfo: {
      title: 'Atendimento'
    },

    data: () => ({
      icons: {
        menu: mdiMenuDown,
        send: mdiSend,
        train: mdiShimmer,
        text: mdiText,
        close: mdiClose,
        arrow: mdiArrowRightThin,
        link: mdiLink
      },
      controller: {
        chat: {
          selected: 'bot',
          input: null,
          label: 'Mensagem',
          info: false,
          summary: {
            value: null,
            label: 'Resumo',
            disabled: false,
            loading: false,
          },
          status: {
            value: null,
            label: 'Status',
            ...status,
            disabled: false,
            loading: false,
          },
          type: {
            value: null,
            label: 'Tipo',
            ...types,
            disabled: false,
            loading: false,
          },
          category: {
            value: null,
            child: null,
            label: 'Categoria',
            ...categories,
            disabled: false,
            loading: false,
          },
          loading: false,
          disabled: false,
          bot: {
            id: 'bot',
            key: 'bot',
            bot: true,
            name: 'Mel',
            summary: 'Chatbot',
            driver: {
              photo: require('@/assets/img/driver-bot-avatar-72.png'),
            },
            messages: [
              {
                type: 'text',
                content: 'Oi {{username}}! Em que posso ajudar?',
                human: true
              }
            ]
          },
        },
        list: {
          items: {},
          order: [['fields.updated', 'fields.created'], ['desc', 'desc']],
          updatedAt: null,
          timer: null,
          loader: null,
          loading: false,
          disabled: false
        }
      },
      view: {
        width: null,
        height: null,
        list: {
          width: null,
          height: null,
          header: {
            width: null,
            height: null,
          }
        },
        chat: {
          width: null,
          height: null,
          header: {
            width: null,
            height: null,
          }
        },
        info: {
          width: null
        }
      },
      device: {
        mobile: device.isMobileOnly,
        tablet: device.isTablet,
        landscape: false,
        width: null,
        height: null,
      },
      disabled: false,
      loading: false,
    }),

    computed: {
      queues: sync('support/state@queues'),
      queue: sync('support/state@queue'),
      drivers: sync('drivers/data@items'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      list () {
        const username = this.user.username;
        const controller = _.cloneDeep(this.controller.list);
        const queues = this.queues;
        const id = this.queue;
        const queue = _.has(queues, id) ? queues[id] : {};
        controller.items = _.mapValues(_.filter(controller.items, ['queue', id]), ticket => {
          const { name, driver } = this.getDriverInfo(ticket);
          return {
            ...ticket,
            name,
            driver,
          }
        })
        const list = {
          ...queue,
          items: [
            this.controller.chat.bot,
            ..._.orderBy(_.values(controller.items), ...controller.order)
          ]
        }
        return list;
      },

      ticket: {
        get () {
          const tickets = this.controller.list.items;
          const selected = this.controller.chat.selected;
          const ticket = !!selected && _.has(tickets, selected) ? tickets[selected] : selected=='bot' ? this.controller.chat.bot : null;
          return !!ticket ? {
            ...ticket,
            ...(selected!=='bot'&&!!ticket ? this.getDriverInfo(ticket) : {})
          } : null;
        },
        set (id) {
          this.controller.chat.selected = id;
        }
      },

      messages: {
        get () {
          const messages = this.ticket.messages;
          return messages;
        },
        set (messages) {
          const target = selected=='bot' ? this.controller.chat.bot : this.controller.list.items[this.ticket.key];
          target.messages = !!messages&&!!this.ticket ? messages : [];
        }
      },

      categories () {
        const categories = this.controller.chat.category.options;
        const list = _.keys(categories);
        return list;
      },
      subcategories () {
        const categories = this.controller.chat.category.options;
        const selected = this.controller.chat.category.value;
        const list = !!selected ? _.clone(categories[selected]) : [];
        return list;
      },

      showInfo () {
        return !!this.ticket&&this.ticket.key!='bot';
      },

      height () {
        const mobileLandscape = this.device.landscape && this.device.mobile;
        return this.device.height - (mobileLandscape ? 0 : 64 + 16) - 64;
      },

      isTraining () {
        const message = this.controller.chat.input;
        return !!message && message.indexOf('>>>')>=0;
      },

      storage () {
        let url = this.getStorageUrl(this.ticket);
        return url;
      }

    },

    filters: {
      formatter (text, formatter=v=>v) {
        return formatter(text);
      },
      brief (ticket, formatter=v=>v) {
        const message = _.isEmpty(ticket.messages) ? '' : _.last(ticket.messages).content;
        return ticket.name + ': ' + formatter(message);
      },
      transition (status, transitions) {
        const transition = _.find(transitions, ['to', status.id]);
        return !!transition ? transition.name : null;
      },
      dateFormat (date) {
        return _.isNil(date) ? '–' : moment(date).format('DD/MMM HH:mm');
      }
    },

    watch: {
      $route: {
        immediate: true,
        deep: true,
        handler (route, from) {
          let queue = null;
          let ticket = null;
          if (_.has(route.params, 'queue')&&!_.isNil(route.params.queue)) {
            queue = route.params.queue;
            this.queue = queue;
            if (_.has(route.params, 'ticket')&&!_.isNil(route.params.ticket)) {
              ticket = route.params.ticket;
              this.ticket = ticket;
            }
          }else{
            if (!!this.queue) this.select(this.queue);
          }
          const list = this.controller.list;
          const updated = !(_.isEmpty(list.items)||moment().diff(list.updatedAt, 's')>60)
          this.get(queue, ...(updated ? [ticket, this.controller.chat] : []));
        }
      },
      'controller.list.items': {
        deep: true,
        handler (items) {
          // load pending items
          const ticket = this.ticket;
          let pending = !!ticket&&_.has(items, ticket.key)&&items[ticket.key].updatedAt===null ? ticket : _.find(_.orderBy(items, ...this.controller.list.order), ['updatedAt', null]);
          if (!!pending) this.get(this.queue, pending.key);
        }
      },
      ticket: {
        deep: true,
        handler (data) {
          const ticket = _.cloneDeep(data);
          this.controller.chat.summary.value = ticket.summary;
          this.controller.chat.status.value = !!ticket.status ? ticket.status.id : null;
          this.controller.chat.type.value = !!ticket.type ? ticket.type.value : null;
          if (!!ticket.category) {
            this.controller.chat.category.value = ticket.category.value;
            this.controller.chat.category.child = _.has(ticket.category, 'child') ? ticket.category.child.value : null;
          }else{
            this.controller.chat.category.value = null;
            this.controller.chat.category.child = null;
          }
        }
      }
    },

    methods: {
      ...services,
      md5,

      select (queue, ticket=null) {
        ticket = ticket=='bot' ? this.controller.chat.bot : _.find(this.controller.list.items, ['id', ticket]);
        const path = `/support/${queue}${!!ticket ? '/'+ticket.key : ''}`;
        console.log('select', queue, ticket, this.$route.path, path);
        if (this.$route.path!=path) this.$router.push(path);
        this.get(queue, !!ticket ? ticket.key : null);
      },

      message (event, human=false, message=null) {
        console.log('message')
        const content = message||this.controller.chat.input;
        if (!!content&&content!='') {
          this.messages.push({
            type: 'text',
            human,
            content
          });
          if (!human) {
            this[this.ticket.id=='bot' ? 'assistant' : 'send'](content);
          }
        }
      },

      format (text) {
        if (!!text) {
          const username = this.user.username;
          text = text
            .replace(/\{\{.+\}\}/, username)
            .replace(/^R:|Resposta:|Support:|Mel:/, '');
          
            text = text.indexOf('<assignee:support>')>=0 ? `${text.replace('<assignee:support>', '&lt;assignee:support&gt;')} => Estou encaminhando seu chamado para nossa Equipe de Suporte. Em breve entrarão em contato 😉` : text;
        }
        return text;
      },

      onMessageClick (message) {
        if (message.content.indexOf('![')===-1) {
          this.controller.chat.input = message.content;
        }
      }, 

      toggleDescription (b=null) {
        console.log(b);
        this.controller.chat.info = b===null ? !this.controller.chat.info : b;
      },

      set (field, value=null) {
        const ticket = _.cloneDeep(this.controller.list.items[this.ticket.key]);
        if (!!value) {
          ticket[field] = value;
        }else{
          this.controller.chat[field].value = ticket[field];
        }
      },

      get (queue=null, ticket=null, loader=this.controller.list) {
        if (ticket=='bot') return;
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const params = !!ticket&&ticket!=='bot' ? 'ticket='+ticket : !!queue ? 'queue='+queue : '';
        
        console.log('get...');

        this.$api.COM
          .get('/com/jira/'+username+'/'+token+'?'+params)
          .then(response => {
            console.log('get => ',response);
            const data = response.data;
            const updatedAt = moment().valueOf();
            if (!!ticket) {
              const description = data.fields.description;
              const status = data.fields.status;
              const type = data.fields.customfield_10042;
              const category = data.fields.customfield_10043;
              const log = JSON.parse(data.fields.customfield_10044);
              const files = _.mapValues(_.keyBy(data.fields.attachment, 'filename'), 'content');
              const comments = data.fields.comment.comments;
              const messages = !!log ? _.concat(_.map(JSON.parse(log.messages), msg => {
                msg.content = markdown(msg.content, files, this.getStorageUrl(data));
                return msg;
              }), parseComments(comments)) : [{ type: 'text', content: data.fields.description }, ...parseComments(comments)];
              this.$set(this.controller.list.items, ticket, { 
                ...this.controller.list.items[ticket], 
                ...data,
                files,
                queue,
                status,
                type,
                category,
                description,
                messages,
                updatedAt
              });
            }else if (!!queue) {
              _.each(data, ticket => {
                const cache = _.has(this.controller.list.items, ticket.key) ? this.controller.list.items[ticket.key] : null;
                if (cache===null) {
                  const summary = ticket.fields.summary;
                  const link = 'https://mobees.atlassian.net/browse/'+ticket.key;
                  this.$set(this.controller.list.items, ticket.key, {
                    ...ticket,
                    queue,
                    status: null,
                    type: null,
                    category: null,
                    messages: [],
                    files: [],
                    name: null,
                    driver: null,
                    summary,
                    link,
                    updatedAt: null
                  })
                }
              });
              if (this.ticket===null||!_.has(this.controller.list.items, this.ticket.key)||(this.ticket.key=='bot'&&this.controller.list.updatedAt===null)) {
                this.select(queue, _.first(_.orderBy(data, ...this.controller.list.order)).id);
              }
              this.controller.list.updatedAt = updatedAt;
            }else{
              _.each(data, queue => {
                this.$set(this.queues, queue.id, queue);
              });
              if (queue===null) {
                // default Todo queue
                this.select(21);
              }
            }
          })
          .catch(error => {
            this.toggleToast(true, 'Aguardando resposta do sistema... ⏳');
            this.loader = setTimeout(($) => {
              $.loader = null;
              $.get(queue, ticket, loader);
            }, 7000, this);
            // this.toggleToast(
            //   true,
            //   `Erro desconhecido. Tente novamente, por favor`,
            //   5000,
            //   false
            // );
            this.handleError(error);
          })
          .finally(() => {
            loader.loading = false;
          });
      },

      update (field) {
        if (this.ticket===null) return;
        const ticket = this.ticket.id;
        const controller = this.controller.chat[field];
        const loader = controller;
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        let params = {};

        switch (field) {
          case 'status':
            const transition = _.find(controller.transitions, ['to', controller.value]);
            if (_.isNil(transition)) {
              return;
            }else{
              params.transition = transition.id;
            }
            break;
          case 'category':
            params.category = {
              value: controller.value,
              child: controller.child,
            }
            break;
            
          default:
            params[field] = controller.value;
            break;
        }
        
        console.log('update...', field, controller.value);

        this.$api.COM
          .post('/com/jira/'+username+'/'+token, { ...params, ticket })
          .then(response => {
            console.log('update => ',response);
            this.set(field, controller.value);
            this.toggleToast(
              true,
              `Chamado atualizado`,
              5000,
              false
            );
            
          })
          .catch(error => {
            this.set(field, null);
            this.toggleToast(
              true,
              `Erro ao atualizar o chamado. Tente novamente, por favor`,
              5000,
              false
            );
            this.handleError(error);
          })
          .finally(() => {
            loader.loading = false;
          });
      },

      send (message, loader=this.controller.chat) {
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const ticket = this.ticket.key;
        const data = {
          cpf: username,
          authTk: token,
          issueId: ticket,
          comment: message,
          public: true
        };
        
        console.log('send...', data);

        this.$api.COM
          .post('/addticketcomment', data)
          .then(response => {
            console.log('send => ',response);
            this.controller.chat.input = null;

            this.message(null, true, this.format(response.data));
          })
          .catch(error => {
            // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 7s
            // this.view.loader = setTimeout(($) => {
            //   this.view.loader = null;
            //   $.getBenefits(script);
            // }, 7000, this, script);
            this.toggleToast(
              true,
              `Erro desconhecido. Tente novamente, por favor`,
              5000,
              false
            );
            this.handleError(error);
          })
          .finally(() => {
            loader.loading = false;
          });
      },

      assistant (message=null, loader=this.controller.chat) {
        loader.loading = true;

        message = !!message ? message : _.last(this.messages).content;
        
        const username = this.user.username;
        const token = this.user.auth.token;
        const data = {
          message
        };
        
        console.log('assistant...', data);

        this.$api.COM
          .post('/com/chat/'+username+'/'+token, data)
          .then(response => {
            console.log('assistant => ',response);
            if (this.ticket.id=='bot') {
              this.controller.chat.input = null;
              this.message(null, true, this.format(response.data));
            }else{
              this.controller.chat.input = this.format(response.data);
            }
          })
          .catch(error => {
            // this.$emit('toggle-alert', 'Aguardando resposta do sistema... ⏳');
            // try again in 7s
            // this.view.loader = setTimeout(($) => {
            //   this.view.loader = null;
            //   $.getBenefits(script);
            // }, 7000, this, script);
            this.toggleToast(
              true,
              `Erro desconhecido. Tente novamente, por favor`,
              5000,
              false
            );
            this.handleError(error);
          })
          .finally(() => {
            loader.loading = false;
          });
      },

      getDriverInfo (ticket) {
        let info;
        if (!!ticket&&'reporter' in ticket.fields) {
          const email = ticket.fields.reporter.emailAddress;
          const driver = _.find(this.drivers, ['email', email]);
          // TODO if not driver, getDriver(id)
          let name = !!driver ? driver.fullname : ticket.fields.reporter.displayName;
          if (name.indexOf('@')>=0) {
            name = name.split('@')[0];
          }else{
            const names = name.split(' ');
            name = names[0] + ' ' + names[names.length-1];
          }
          const storage = !!driver ? `https://storage.mobees.com.br/infodrivers/cpf=${driver.cpf}/` : null;
          info = { name, storage, driver };
        }
        return info;
      },

      getStorageUrl (ticket) {
        let url;
        if (!!ticket&&ticket.id!=='bot') {
          const { driver } = this.getDriverInfo(ticket);
          url = !!driver ? `https://storage.mobees.com.br/infodrivers/cpf=${driver.cpf}/` : null;
        }
        return url;
      },

      getDriverList (params, loader, callback) {
        if (params.status=='ACT') _.unset(params, 'qtd');
        console.log('get list for ', params);
        loader.loading = true;
        
        const username = this.user.username;
        const token = this.user.auth.token;

        const query = _.reduce(params, (s,v,k) => {
          return s + (_.isNil(v) ? '' : '&'+k+'='+v);
        },'');
        
        this.$api.COM({
          url: '/searchprofiledriverextadmin/'+username+'/'+token,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: params,
        })
        .then(response => {
          console.log('getDriverList => ',response);
          if(response.data.retorno==200){
            
            if (response.data.dados.length>0) {
              const drivers = this.processDrivers(response.data.dados);
              this.cacheDrivers(drivers, this.drivers);
            }

          }else if(response.data.retorno==404){
            // no results
          }else if(response.data.retorno==401){
            this.getout();
            this.handleError(response.data.retorno, 'Sua sessão expirou...');
          }else{
            this.handleError(response.data.retorno, 'Erro desconhecido. Vamos investigar o que houve! 😊');
          }
        })
        .catch(error => {
          this.handleError(error);
        })
        .finally(() => {
          loader.loading = false;
          if (!_.isNil(callback)) callback();
        });
      },

      onResize () {
        this.device.width = window.innerWidth;
        this.device.height = window.innerHeight;
        this.device.landscape = this.device.width > this.device.height;
        // console.log('display update', this.device.mobile, this.device.landscape);
        if (_.every(['container', 'list-header', 'chat-header'], el => el in this.$refs)) {
          const view = this.view;
          const container = this.$refs['container'];
          view.width = container.clientWidth;
          view.height = container.clientHeight;
          
          const listHeader = this.$refs['list-header'].$el;
          view.list.header.width = listHeader.clientWidth;
          view.list.header.height = listHeader.clientHeight;
          view.list.width = view.list.header.width;
          view.list.height = view.height - view.list.header.height;

          const chatHeader = this.$refs['chat-header'];
          view.chat.header.width = chatHeader.clientWidth;
          view.chat.header.height = chatHeader.clientHeight;
          view.chat.width = view.chat.header.width;
          view.chat.height = view.height - view.chat.header.height;

          const breakpoint = this.breakpoint();
          switch (breakpoint) {
            case 'xs':
              view.info.width = '90%';
              break;
            case 'sm':
              view.info.width = '50%';
              break;
              
            default:
              view.info.width = '33%';
              break;
          }

        }else{
          setTimeout(($) => {
            $.onResize();
          }, 250, this);
        }
      },
    },

    components: {
      DriverList: () => import('@/components/DriverList'),
      MField: () => import('@/components/mField'),
      IconBase: () => import('@/components/IconBase'),
      AvatarDefault: () => import('@/components/icons/AvatarDefault'),
      BotUi
    },

    mounted () {
      this.onResize();
      this.getDriverList({ status: 'ACT' }, this.controller.list);
    }
  }
</script>