export const status = {
  options: [
    {
      "id": "3",
      "name": "Em andamento",
    },
    {
      "id": "10010",
      "name": "On Hold",
    },
    {
      "id": "10011",
      "name": "Concluído",
    },
    {
      "id": "10012",
      "name": "Tarefas Pendentes",
    },
    {
      "id": "10088",
      "name": "Refunded notice",
    },
    {
      "id": "10089",
      "name": "Refunded",
    },
    {
      "id": "10083",
      "name": "Scheduled",
    },
    {
      "id": "10005",
      "name": "Waiting for customer",
    }
  ],
  transitions: {
		"211": {
			"id": "211",
			"name": "In Progress",
      "to": "3"
		},
		"221": {
			"id": "221",
			"name": "Work in progress",
			"to": "10010",
		},
		"231": {
			"id": "231",
			"name": "Done",
			"to": "10011"
		},
		"251": {
			"id": "251",
			"name": "Refunded notice",
			"to": "10088",
		},
		"261": {
			"id": "261",
			"name": "Refunded",
			"to": "10089",
		},
		"81": {
			"id": "81",
			"name": "Start",
			"to": "3",
		},
		"161": {
			"id": "161",
			"name": "Restart",
			"to": "10012",
		},
		"181": {
			"id": "181",
			"name": "Agendado",
			"to": "10083",
		},
		"271": {
			"id": "271",
			"name": "Aguardar Input",
			"to": "10005",
		},
		// "171": {
		// 	"id": "171",
		// 	"name": "Resolved",
		// 	"to": "10011",
		// }
  }
}

export const types = {
  options: ['Bug', 'Tarefa', 'Issue', 'Questionamento', 'Clube de Vantagens', 'Pesquisa'],
}

export const categories = {
  options: {
    'Aplicativo': ['Código', 'Instalação', 'Login / Senha', 'Perfil'],
    'Buzzer': ['Marcação de Horas', 'Tela preta', 'Ponto Verde', 'Modem não conecta', 'Aquecimento', 'Barulho no Buzzer', 'Sensor de Luminosidade', 'Tela solta', 'Painel de LED danificado', 'Tela embaralhada', 'Tela branca', 'Tela congelada', 'Buzzer não desliga', 'Troca de Veículo', 'Retirada Parcial', 'Reinstalação', 'Retirada', 'Sem energia', 'Continuidade', 'Erro de hardware GPS', 'Manutenção', 'Erro de hardware KMT', 'Erro de RTC', 'Tela desligada'],
    'Disjuntor': ['Disjuntor danificado'],
    'Parceria': ['Ação Publicitária', 'Cheque GNV', 'Flashmob'],
    'Processo': ['Critérios de Seleção', 'Indicaçao de Motoristas', 'Indicação de Anunciantes', 'Quando serei chamado?', 'Rack', 'Renda Extra', 'Revisão', 'Veículos Compatíveis', 'Revisão Online', 'Inatividade', 'Checklist'],
    'Fio': ['Fio descolando', 'Fio com mau contato'],
    'GPS': ['Solto'],
    'Kismet': ['Solto'],
    'Rack': ['Barulho no rack', 'Garra Frouxa', 'Garra Solta', 'Garra enferrujada', 'Entrando água'],
    'Site': ['Ajuste de CPF', 'Cadastro'],
    'Clube de Vantagens': ['Vistoria GNV', 'Planos de Saúde', 'Lecadô', 'Pet Shop', 'Regulagem GNV', 'Mc Donalds', 'Lecadô MOBEES10', 'Lecadô 50', 'MedPass Premium', 'MedPass Básico', 'Teatro', 'Lecadô 02/2023', 'Lecadô 03/2023', 'Lecadô 04/2023', 'Lecadô 05/2023', 'Lecadô 06/2023', 'Lecadô 07/2023', 'Lecadô 08/2023', 'Lecadô 09/2023', 'Lecadô 10/2023', 'Lecadô 11/2023', 'Lecadô 12/2023'],
    'Pesquisa': ['Desafio 99', 'Adesivos', 'Onboarding']
  },
}

export const parseComments = (comments, messages=[]) => {
  return _.map(_.filter(comments, h =>{
    return h.jsdPublic && _.isNil(_.find(messages, m => {
        return m.content == h.body.replace(/\\/g, '');
      }));
    }), m => {
      return {
        interaction: 'message',
        content: m.body.replace(/\\/g, ''),
        timestamp: m.created,
        support: m.author.emailAddress=='suporte@mobees.com.br',
      }
  });
}

export const markdown = (content, files, storage) => {
  return content
    .replace(/([^\.]*\.(jpg|png),)|([^\.]*\.(jpg|png))$|(\!\[?.+\.(jpg|png)(\|thumbnail)?\!)/gmi, (match, $1, $2) => {
      const filename = _.trim(match.replace(',',''));
      return filename in files ? `![${filename}](${files[filename]})` : match;
    })
    .replace(/\(\@(.*?)\)/gim, (match, $1) => `(${storage+$1})`);
}

export default {
  status,
  types,
  categories,
  parseComments,
  markdown
}